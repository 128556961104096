<template>
  <div class="weight-seelct">
    <el-row :gutter="10">
      <el-col :md="5" :xs="12">
        <el-input-number
          v-model="weight"
          :precision="precision"
          :min="min"
          :step="min"
          controls-position="right"
          :placeholder="$t('productManagement.l')"
        ></el-input-number>
      </el-col>
      <el-col :md="5" :xs="12">
        <el-select
          v-model="unitTimes"
          :placeholder="$t('resident.pleaseSelect')"
          @change="changeUnit"
        >
          <div slot="prefix" class="weight-unit">
            {{ $t("product.unit") }}
          </div>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="$t(item.label)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "VolumeInputNumber",
  props: {
    // value的默认参数是kg
    value: {
      type: Number,
      default: 1, // 单位kg
    },
  },
  data() {
    return {
      // 默认kg
      unitTimes: 1,
      options: [
        // 磅
        {
          value: 0.45359237,
          label: "productManagement.lb",
        },
        // 千克
        {
          value: 1,
          label: "productManagement.kg",
        },
        // 格令
        {
          value: 0.0000648,
          label: "productManagement.gr",
        },
        // 盎司
        {
          value: 0.028349523125,
          label: "productManagement.oz",
        },
      ],
    };
  },
  computed: {
    weight: {
      get() {
        // 转换位数字进行单位转换并保留三位小数
        const result = Number((this.value / this.unitTimes).toFixed(3));
        // console.log("result:", this.value, this.unitTimes, result);
        return result > this.min ? result : this.min;
      },
      set(newVal) {
        this.valueOutPut(newVal);
      },
    },
    // 计数器允许的最小值
    min: function () {
      switch (this.unitTimes) {
        // kg每次增加0.001 磅每次增加0.002 格令每次增加15 盎司每次增加0.035
        case 1:
          return 0.001;
        case 0.45359237:
          return 0.002;
        case 0.0000648:
          return 15;
        case 0.028349523125:
          return 0.035;
        default:
          return 0.01;
      }
    },
    // 计数器的数值精度
    precision: function () {
      switch (this.unitTimes) {
        // kg保留三位小数 磅保留三位小时 格令保留两位小数 盎司保留三位小数
        case 1:
          return 3;
        case 0.45359237:
          return 3;
        case 0.0000648:
          return 2;
        case 0.028349523125:
          return 3;
        default:
          return 2;
      }
    },
  },
  methods: {
    valueOutPut(newVal) {
      // 转化为数字进行单位换算并保留三位小数
      const outPutNumber = Number((newVal * this.unitTimes).toFixed(3));
      // console.log("outPutNumber:", outPutNumber);
      this.$emit("input", outPutNumber);
    },
    // 下拉框的change事件
    changeUnit(e) {
      console.log("changeUnit-e:", e);
    },
  },
};
</script>

<style lang="scss">
.weight-seelct {
  .el-input-number {
    width: 100%;
  }
  .weight {
    &-times {
      text-align: center;
    }
    &-unit {
      margin-right: 10px;
    }
  }
  .el-input--prefix {
    .el-input__inner {
      padding-left: 50px;
    }
  }
}
</style>