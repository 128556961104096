<template>
  <div v-if="error">
    <i class="el-icon-warning" style="color: #e6a23c" />
    {{ $t("common.Failedclick") }}
    <el-button type="text" @click="reload">{{ $t("common.Reload") }}</el-button>
  </div>
  <el-select
    v-else
    v-loading="loading"
    v-model="value"
    :placeholder="$t('common.selectPlaceholder')"
    autocomplete="autocomplete"
    filterable
    @change="handleChange"
  >
    <el-option
      v-for="item in options"
      :key="item.label"
      :label="$t('category.' + item.label)"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>

<script>
import { GetMainCategoryList } from "@/utils/api";
import { findCategorySelectChildren } from "@/utils";
export default {
  data() {
    return {
      value: "",
      options: [],
      loading: false,
      error: false,
    };
  },
  created() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      this.loading = true;
      GetMainCategoryList()
        .then((res) => {
          // console.log("GetMainCategoryList", res);
          this.options = findCategorySelectChildren("0", res.data.rows);
          // console.log(this.options, "categorys");
          this.loading = false;
        })
        .catch((error) => {
          console.log("getCategory-error:", error);
          this.loading = false;
          this.error = true;
        });
    },
    reload() {
      this.error = false;
      this.getCategory();
    },
    handleChange(e) {
      this.$emit("input", e);
    },
  },
};
</script>