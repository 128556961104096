<template>
  <div>
    <el-row>
      <el-col>
        <el-input
          type="number"
          :value="value"
          :placeholder="$t(placeholder)"
          autocomplete="autocomplete"
          @input="changeInput($event)"
        ></el-input>
      </el-col>
    </el-row>
    <el-row class="number-msg">
      <el-col v-if="value === 1">
        <i class="sokogate icon-single_box single_box"></i>&nbsp;{{
          $t("product.singlebox")
        }}
      </el-col>
      <el-col v-else-if="value > 1">
        <i class="sokogate icon-many_box_inside many_box"></i>&nbsp;{{
          $t("product.manybox")
        }}
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
    },
    value: {
      type: Number,
    },
  },
  methods: {
    changeInput(e) {
      // console.log("change", e);
      this.$emit("input", Number(e));
      this.$store.commit("setincMinimun", Number(e));
    },
  },
};
</script>

<style lang="scss" scoped>
.number-msg {
  margin-top: 3%;
  .single_box {
    font-size: 60px;
  }
  .many_box {
    font-size: 90px;
  }
}
</style>