<template>
    <div class="freight-mode-box">
      <el-select
        v-if="pagePath"
        :placeholder="$t('common.selectPlaceholder')"
        autocomplete="autocomplete"
        :value="value"
        @change="getSelectDep"
      >
        <el-radio-group @change="getSelectDep">
          <el-option
            v-for="(item, index) in departments"
            :label="item.label"
            :value="item.value"
            :key="index"
            style="width: 900px;"
          ></el-option>
        </el-radio-group>
      </el-select>
  
      <el-select
        v-else
        :placeholder="$t('common.selectPlaceholder')"
        autocomplete="autocomplete"
        multiple
        collapse-tags
        :value="value"
        @change="getSelectDep"
      >
        <el-checkbox v-model="checked" @change="selectAll">{{
          $t("common.selectall")
        }}</el-checkbox>
        <el-option
          v-for="(item, index) in departments"
          :label="item.label"
          :value="item.value"
          :key="index"
        ></el-option>
      </el-select>
    </div>
  </template>
    
    <script>
  export default {
    props: {
      value: {
        type: Array,
        default() {
          return [];
        },
      },
      pagePath: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        checked: false,
        radio: 3,
        departments: [
          {
            value: 103,
            label: this.$t("menu.battery"),
          },
          {
            value: 105,
            label: this.$t("menu.e-cigarette"),
          },
          {
            value: 106,
            label: this.$t("menu.textile"),
          },
          {
            value: 107,
            label: this.$t("menu.box"),
          },
          {
            value: 108,
            label: this.$t("menu.ups"),
          },
          {
            value: 109,
            label: this.$t("menu.SupportingBattery"),
          },
          {
            value: 110,
            label: this.$t("menu.noBattery"),
          },
          {
            value: 111,
            label: this.$t("menu.powerSupply"),
          },
          {
            value: 139,
            label: this.$t("menu.Cargo"),
          },
          {
            value: 203,
            label: this.$t("menu.capacitance"),
          },
          {
            value: 204,
            label: this.$t("menu.internalPower"),
          },
          {
            value: 205,
            label: this.$t("menu.powerDistribution"),
          },
          {
            value: this.$route.path == "/product/edit" ? 140 : 139,
            label: this.$t("menu.compressedClothesJeans"),
          },
          {
              value: this.$route.path == "/product/edit" ? 141 : 139,
            label: this.$t("menu.compressedClothesButtons"),
          },
        ],
      };
    },
    watch: {
      value: {
        handler(val) {
          // console.log(val, "value");
          if (val.length === this.departments.length) {
            this.checked = true;
          }
        },
      },
      deep: true,
      immediate: true,
    },
    computed: {
      select: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        },
      },
    },
    methods: {
      selectAll(val) {
        // console.log(val, "val");
        if (val) {
          this.select = this.departments.map((v) => v.value);
        } else {
          this.select = [];
        }
      },
      getSelectDep(department) {
        console.log("department", department);
        this.select = department;
        if (department.length === this.departments.length) {
          this.checked = true;
        } else {
          this.checked = false;
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
.el-checkbox {
  text-align: right;
  width: 100%;
  padding-right: 10px;
}
</style>