<template>
  <el-select
    v-model="value"
    :placeholder="$t('common.selectPlaceholder')"
    autocomplete="autocomplete"
    filterable
    @change="handleChange"
  >
    <el-option
      v-for="item in options"
      :key="item.label"
      :label="$t('category.' + item.label)"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>

<script>
import { GetMainCategoryList } from "@/utils/api";
import { findCategorySelectChildren } from "@/utils";
export default {
  data() {
    return {
      value: "",
      options: [],
    };
  },
  created() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      GetMainCategoryList()
        .then((res) => {
          // console.log("GetMainCategoryList", res);
          this.options = findCategorySelectChildren("0", res.data.rows);
          // console.log(this.options, "categorys");
        })
        .catch((error) => {
          console.log("getCategory-error:", error);
        });
    },
    handleChange(e) {
      this.$emit("input", [e]);
    },
  },
};
</script>