<template>
  <div>
    <el-row :gutter="1">
      <el-col :span="18" :md="6">{{ $t("product.colorText") }} </el-col>
      <el-col :span="4" :md="2">{{ $t("product.img") }} </el-col>
      <el-col :span="18" :md="6">{{ $t("product.remark") }} </el-col>
      <el-col :span="4" :md="2"> </el-col>
    </el-row>
    <template v-for="(item, index) in value">
      <el-row
        v-if="!!item.avaliable"
        :key="`color-input-${index}`"
        style="margin-bottom: 10px"
        type="flex"
        :gutter="1"
      >
        <el-col :span="18" :md="6">
          <el-input
            v-model.trim="item.specName"
            class="input-with-prepend"
            @blur="nameBlur($event, item)"
            :ref="'color-input-name-' + index"
            :placeholder="$t('product.color')"
          >
          </el-input>
        </el-col>
        <el-col :span="4" :md="2">
          <el-dropdown
            v-if="item.img.length"
            placement="right"
            @command="item.img = ''"
          >
            <el-image
              :src="`${item.img}?x-oss-process=style/w256`"
              :preview-src-list="[item.img]"
              class="imgbg color-cover"
              fit="contain"
            ></el-image>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-delete">
                {{ $t("common.delete") }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <material-selector
            v-else
            :count="1"
            type="image"
            @receiver="onSelectDone($event, item)"
          >
            <template v-slot:custom-open-button>
              <el-button
                icon="el-icon-picture-outline"
                type="primary"
                plain
              ></el-button>
            </template>
          </material-selector>
        </el-col>
        <el-col :span="18" :md="6">
          <el-input
            v-model.trim="item.remark"
            class="input-with-prepend"
            :placeholder="$t('product.specRemark')"
          >
          </el-input>
        </el-col>
        <el-col :span="4" :md="2">
          <el-popconfirm
            v-if="item.specName.length"
            :title="$t('common.confirmDelete')"
            @confirm="del(index)"
          >
            <el-button
              slot="reference"
              icon="el-icon-delete"
              type="danger"
              plain
            ></el-button>
          </el-popconfirm>
        </el-col>
      </el-row>
    </template>
    <el-button
      v-if="!hasEmptyName"
      icon="el-icon-plus"
      type="primary"
      plain
      @click="add"
    ></el-button>
    <el-collapse v-if="avaliableCount > 0" v-model="unavailable" accordion>
      <el-collapse-item name="unavailable">
        <template slot="title">
          <el-button type="text">
            {{ $t("product.showDeletedItems") }}
            ({{ avaliableCount }})
          </el-button>
        </template>
        <template v-for="(item, index) in value">
          <el-row
            :key="`color-input-${index}`"
            style="margin-bottom: 10px"
            type="flex"
            :gutter="1"
            v-if="!item.avaliable"
          >
            <el-col :span="18" :md="6">
              <el-input
                v-model.trim="item.specName"
                class="input-with-prepend"
                :disabled="true"
              >
              </el-input>
            </el-col>
            <el-col :span="4" :md="2">
              <el-image
                v-if="item.img.length"
                :src="`${item.img}?x-oss-process=style/w256`"
                :preview-src-list="[item.img]"
                class="imgbg color-cover"
                fit="contain"
              >
              </el-image>
              <el-button
                v-else
                icon="el-icon-picture-outline"
                type="primary"
                plain
                :disabled="true"
              >
              </el-button>
            </el-col>
            <el-col :span="18" :md="6">
              <el-input
                v-model.trim="item.remark"
                class="input-with-prepend"
                :disabled="true"
              >
              </el-input>
            </el-col>
            <el-col :span="4" :md="2">
              <el-button type="success" plain @click="restore(index)">
                <i class="sokogate icon-yihuifu-xiugai" />
              </el-button>
            </el-col>
          </el-row>
        </template>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import MaterialSelector from "@/components/MaterialSelector";
export default {
  components: { MaterialSelector },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      unavailable: "",
    };
  },
  computed: {
    hasEmptyName: function () {
      return this.value.some((v) => !v.specName.length);
    },
    avaliableCount: function () {
      return this.value.filter((v) => !v.avaliable).length;
    },
  },
  methods: {
    onSelectDone(res, item) {
      console.log(res, item);
      item.img = res.filename;
    },
    nameBlur(e, item) {
      const value = e.target.value;
      console.log("nameBlur-value:", value);
      const sameSpecNameItem = this.value.find(
        (v) => v.specName === value && v !== item
      );
      console.log("nameBlur-sameSpecNameItem:", sameSpecNameItem);
      if (sameSpecNameItem) {
        // console.log("判断输入的字符串已存在列表");
        this.$message({
          type: "warning",
          message: this.$t("product.warningmsg"),
        });
        item.specName = "";
        if (!sameSpecNameItem.avaliable) {
          sameSpecNameItem.avaliable = 1;
        }
      } else {
        // console.log("else");
      }
      this.$nextTick(() => {
        this.findAndDelEmptyDic();
      });
    },
    findAndDelEmptyDic() {
      if (this.hasEmptyName) {
        const emptyNameDic = this.value.find((v) => !v.specName.length);
        const hasId = Object.prototype.hasOwnProperty.call(emptyNameDic, "id");
        if (hasId) {
          emptyNameDic.avaliable = 0;
        } else {
          const emptyNameDicIndex = this.value.indexOf(emptyNameDic);
          this.value.splice(emptyNameDicIndex, 1);
        }
      }
    },
    add() {
      if (this.hasEmptyName) {
        this.$message({
          type: "success",
          message: "有修改未保存",
        });
      } else {
        this.$emit("input", [
          ...this.value,
          {
            avaliable: 1,
            img: "",
            specName: "",
            title: "",
          },
        ]);
        setTimeout(() => {
          const index = this.value.length - 1;
          this.$refs["color-input-name-" + index][0].focus();
        }, 100);
      }
    },
    del(index) {
      this.value[index].avaliable = 0;
    },
    restore(index) {
      this.value[index].avaliable = 1;
    },
    closeTips() {
      this.showTips = false;
    },
  },
};
</script>

<style lang="scss">
.input-with-prepend {
  padding-right: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;

  .el-input__suffix {
    height: auto;
  }
}
.color-cover {
  width: 40px;
  height: 40px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.el-collapse,
.el-collapse-item__header,
.el-collapse-item__wrap {
  border: 0;
}
</style>