<template>
  <div class="upload-image">
    <el-upload
      class="upload-oss"
      drag
      action
      :http-request="handleUpload"
      multiple
      :show-file-list="false"
      accept="image/webp, image/jpg, image/jpeg, image/png, image/gif"
    >
      <slot name="img">
        <div @drop="onDrop($event)">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            {{ $t("productManagement.uploadTips") }}
            <em>{{ $t("productManagement.uploadClick") }}</em>
          </div>
        </div>
      </slot>
    </el-upload>
  </div>
</template>

<script>
import { uploadoss } from "@/helper/api.js";
import { getFileNameMd5, imageUrlToBase64ToFile } from "@/helper/ali-oss";
// import { createData,renderData } from "@/helper/api.js";
import { GetOssPolicyToken } from "@/utils/api";
export default {
  data() {
    return {
      storeId: "",
      accessid: "",
      expire: "",
      host: "",
      policy: "",
      signature: "",
    };
  },
  created() {
    this.storeId = localStorage.getItem("storeId");
  },
  methods: {
    // oss签名
    getOssSignature() {
      return new Promise((resolve, reject) => {
        try {
          console.log("getOssSignature:");
          this.ossSignatureAvailable()
            .then((res) => {
              console.log("ossSignatureObj:", res);
              resolve(res);
            })
            .catch((err) => {
              console.log("ossSignatureObj-err:", err);
              // renderData("GET", "oss")
              //   .then((res) => {
              //     console.log("getOssSignature-res:", res);
              //     localStorage.setItem(
              //       "ossSignature",
              //       JSON.stringify(res.data)
              //     );
              //     resolve(res.data);
              //     // this.accessid = res.data.accessid;
              //     // this.expire = res.data.expire;
              //     // this.host = res.data.host;
              //     // this.policy = res.data.policy;
              //     // this.signature = res.data.signature;
              //   })
              //   .catch((error) => {
              //     reject(error);
              //   });
              GetOssPolicyToken()
                .then((res) => {
                  console.log("GetOssFileList", res);
                  localStorage.setItem(
                    "ossSignature",
                    JSON.stringify(res.data)
                  );
                  resolve(res.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
        } catch (error) {
          reject(error);
        }
      });
    },

    ossSignatureAvailable() {
      return new Promise(function (resolve, reject) {
        console.log("ossSignatureAvailable:");
        try {
          const ossSignatureJson = localStorage.getItem("ossSignature");
          console.log("ossSignatureJson:", ossSignatureJson);
          if (ossSignatureJson) {
            const ossSignatureObj = JSON.parse(ossSignatureJson);
            const nowUnix = Math.round(new Date().getTime() / 1000);
            if (
              ossSignatureObj &&
              ossSignatureObj.expire &&
              ossSignatureObj.expire > nowUnix &&
              ossSignatureObj.expire - nowUnix > 60 * 60 // 有效期大于1小时
            ) {
              resolve(ossSignatureObj);
            } else {
              reject(new Error("ossSignature expired"));
            }
          } else {
            reject(new Error("empty ossSignature"));
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    // 上传事件
    async handleUpload(option) {
      console.log("getAuth-option:", option);
      const fileName = await getFileNameMd5(option.file);
      console.log("fileName:", fileName);
      // const type = option.file.type;
      // const size = option.file.size;
      // const restype = type.substring(0, type.indexOf("/"));

      const OssSignatureObj = await this.getOssSignature();
      // console.log("OssSignatureObj:", OssSignatureObj);
      await uploadoss("POST", OssSignatureObj.host, {
        key: fileName,
        OSSAccessKeyId: OssSignatureObj.accessid,
        policy: OssSignatureObj.policy,
        signature: OssSignatureObj.signature,
        success_action_status: 200,
        file: option.file,
      }).then(() => {
        // createData("POST", "oss", {
        //   storeId: this.storeId || "",
        //   filename: fileName,
        //   filealia: option.file.name,
        //   filetype: restype,
        //   folder: "image",
        //   size: size,
        // }).then((uploadRes) => {
        //   const url = `https://oss.sokogate.com/${fileName}`;
        //   console.log("handleUpload-uploadRes:", uploadRes, url);
        //   this.$emit("onsuccess", { filename: url });
        // });

        // AddOssFileToregister({
        //   categoryId: this.storeId || "",
        //   filename: fileName,
        //   filetype: restype,
        //   size: size,
        // }).then((res) => {
        const url = `https://oss.sokogate.com/${fileName}`;
        // console.log("handleUpload-uploadRes:", res, url);
        this.$emit("onsuccess", { filename: url });
        // });
      });
    },

    onDrop(ev) {
      console.log("onDrop-ev:", ev);
      ev.preventDefault();
      const url = ev.dataTransfer.getData("URL");
      console.log("onChange-url:", url);
      if (url) {
        // 远程图片
        imageUrlToBase64ToFile(url)
          .then((file) => {
            this.handleUpload({ file });
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              message: this.$t("common.invalidPictureAddress"),
              type: "error",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_responsive.scss";

.upload-image {
  width: 100%;
  height: 100%;
  .upload-oss {
    width: 100%;
    height: 100%;
    .el-upload.el-upload--text {
      width: 100%;
      height: 100%;
      .el-upload-dragger {
        width: 100%;
        height: 100%;

        .el-icon-upload {
          margin-top: 15px;
        }
      }
    }
  }
}
// .upload-oss {
//   width: 360px;
//   height: 180px;

//   @include mobile {
//     width: 100px;
//     height: 100px;
//   }

//   .dorp {
//     @include mobile {
//       display: none;
//     }
//   }
// }
</style>
