<template>
  <div>
    <!-- <p>{{ value }}</p> -->
    <!-- {{ avaliableList }} -->
    <!-- <p>{{ discardList }}</p> -->
    <el-row :gutter="20">
      <el-col :span="11" :md="4">
        <span v-if="changeVal">{{ $t("order.quantity") }}</span>
        <span v-else-if="changeVal == false">{{ $t("pricing.weight") }}</span>
        {{ $t("product.lowerprice") }}
      </el-col>
      <el-col :span="2" :md="1"> <i class="el-icon-minus" /></el-col>
      <el-col :span="11" :md="4">
        <span v-if="changeVal">{{ $t("order.quantity") }}</span>
        <span v-else-if="changeVal == false">{{ $t("pricing.weight") }}</span>
        {{ $t("product.topprice") }}
      </el-col>

      <el-col :span="11" :md="5">
        {{ $t("pricing.price") }}
        <span v-if="changeVal">CBM</span>
        <span v-else-if="changeVal == false">KG</span>
        <span>)</span>
      </el-col>
      <el-col :span="11" :md="5">
        {{ $t("pricing.customFees") }}
        <span v-if="changeVal">CBM</span>
        <span v-else-if="changeVal == false">KG</span>
        <span>)</span>
      </el-col>
      <el-col :span="8" :md="4"> </el-col>
      <el-col :span="5" :md="2"> </el-col>
    </el-row>

    <template v-for="(item, index) in avaliableList">
      <!-- v-if="item.avaliable" -->
      <el-row
        v-if="item.avaliable"
        :key="`ladder-input-${index}`"
        style="margin-bottom: 10px"
        type="flex"
        justify="start"
        align="middle"
        :gutter="20"
      >
        <!-- min input -->
        <el-col :span="11" :md="4">
          <el-input
            v-model.number="item.min"
            readonly
            :ref="'ladder-input-min-' + index"
            class="min-input number-input"
          >
            <!-- <div slot="prepend">
            {{ $t("product.ladder") }}
          </div> -->
          </el-input>
        </el-col>
        <el-col :span="2" :md="1" class="from-to">
          <i class="el-icon-minus" />
        </el-col>
        <!-- max input -->
        <el-col :span="11" :md="4">
          <span v-if="index > 0 && index === avaliableList.length - 1"
            >Unlimited</span
          >
          <el-input-number
            v-else
            v-model.number="item.max"
            :min="item.min + 1"
            :step="1"
            step-strictly
            controls-position="right"
            :ref="'ladder-input-max-' + index"
            :placeholder="$t('product.limit')"
            class="number-input"
            @change="checkMinMaxChange()"
          >
          </el-input-number>
          <!-- @change="onMaxChange($event, index)" -->
        </el-col>
        <!-- price input -->
        <el-col :span="11" :md="5">
          <!-- <el-input-number
            v-model.number="item.price"
            :precision="2"
            :step="0.01"
            :min="0.01"
            step-strictly
            controls-position="right"
            :ref="'ladder-input-price-' + index"
            :placeholder="$t('pricing.price')"
            class="number-input"
            size="small"
          >
          </el-input-number> -->
          <price-input
            v-model.number="item.price"
            :config="{
              size: 'small',
              controlsPosition: 'right',
            }"
          />
        </el-col>
        <!-- customFees input -->
        <el-col :span="11" :md="5">
          <!-- <el-input-number
            v-model.number="item.customFees"
            :precision="2"
            :step="0.01"
            :min="0.01"
            step-strictly
            size="small"
            controls-position="right"
            :ref="'ladder-input-customFees-' + index"
            :placeholder="$t('pricing.customFees')"
            class="number-input"
          >
          </el-input-number> -->
          <price-input
            v-model.number="item.customFees"
            :config="{
              size: 'small',
              controlsPosition: 'right',
            }"
          />
        </el-col>
        <el-col :span="5" :md="2">
          <el-popconfirm
            v-if="item.max > 0 && index > 1"
            :title="$t('common.confirmDelete')"
            @confirm="del(item)"
          >
            <el-button
              slot="reference"
              icon="el-icon-delete"
              type="danger"
              plain
            ></el-button>
          </el-popconfirm>
        </el-col>
      </el-row>
    </template>
    <!-- <el-row> last row is not max 0 {{ hasUnlimited }}</el-row> -->

    <el-button
      v-if="!hasEmptyNum"
      icon="el-icon-plus"
      type="primary"
      plain
      @click="add"
    ></el-button>

    <!-- <el-button
      type="primary"
      class="ladder-submit"
      @click="$emit('submit', [...avaliableList, ...discardList])"
    >
      {{ $t("product.generateSkuList") }}
    </el-button> -->
  </div>
</template>

<script>
import PriceInput from "@/components/s-ui/form/PriceInput";
export default {
  components: { PriceInput },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    changeVal: {
        type: Boolean,
        required: true
    },
  },
  data() {
    return {
      defaultLadder: [
        {
          avaliable: 1,
          min: 1,
          max: 2,
          price: 1,
          customFees: 2,
        },
        {
          avaliable: 1,
          min: 3,
          max: 4,
          price: 1,
          customFees: 2,
        },
      ],
    };
  },
  computed: {
    hasEmptyNum: function () {
      return this.avaliableList.some((v) => !v.max);
    },
    avaliableList: function () {
      const avaliableList = this.value.filter((v) => v.avaliable);
      if (avaliableList.length === 0) {
        // 如果列表为空，则，初始化列表数据
        return this.defaultLadder;
      } else if (avaliableList.length === 1) {
        // 某些旧数据，可能只设置了一行阶梯，默认加一行结尾
        const firstCel = avaliableList[0];
        return [
          firstCel,
          {
            ...firstCel,
            min: firstCel.max + 1,
            max: firstCel.max + 2,
          },
        ];
      } else {
        return avaliableList;
      }
    },
    discardList() {
      return this.value.filter((v) => !v.avaliable);
    },
    // avaliableCount: function () {
    //   return this.value.filter((v) => !v.avaliable).length;
    // },
    // hasUnlimited() {
    //   if (this.value.length > 0) {
    //     const lastItem = this.value[this.value.length - 1];
    //     return lastItem.max === 0;
    //   } else {
    //     return false;
    //   }
    // },
  },
  mounted() {
    if (this.value.length > 1) {
      this.checkMinMaxChange();
    } else {
      this.$emit("input", [...this.avaliableList, ...this.discardList]);
    }
  },
  methods: {
    checkMinMaxChange(index = 0) {
      const curItem = this.avaliableList[index];
      const curMax = curItem.max; // 本行的max
      const nextIndex = index + 1;
      const nextCel = this.avaliableList[nextIndex]; // 下一行数据
      console.log("nextIndex:", nextIndex, "before-nextCel:", nextCel);
      if (nextCel) {
        // if (isNaN(curMax)) {
        //   this.del(nextCel);
        //   return false;
        // }
        nextCel.min = curMax + 1;
        if (nextCel.min < nextCel.max) {
          console.log("nextIndex:", nextIndex, "min < max:", nextCel);
        } else {
          nextCel.max = nextCel.min + 1; // 保持下一行max数字大于min
          console.log("nextIndex:", nextIndex, "after-nextCel:", nextCel);
        }

        this.$nextTick(() => {
          this.checkMinMaxChange(nextIndex);
        });
      } else {
        console.log("nextIndex:", nextIndex, "no next:", index, nextCel);
      }
    },
    add() {
      if (this.hasEmptyNum) {
        console.log("有修改未保存");
      } else {
        const lastCel = this.avaliableList[this.avaliableList.length - 1];
        console.log("lastCel:", lastCel);
        const min = lastCel ? lastCel.max + 1 : 1;
        const max = min + 1;
        const newCel = {
          avaliable: 1,
          min,
          max,
          price: 1,
          customFees: 1,
        };
        // const unAvaliable = this.findUnAvaliable(newCel);
        // if (unAvaliable) {
        //   unAvaliable.avaliable = 1;
        // } else {
        console.log(this.avaliableList);
        this.$emit("input", [
          ...this.avaliableList,
          newCel,
          ...this.discardList,
        ]);
        // }
        // setTimeout(() => {
        //   const index = this.value.length - 1;
        //   this.$refs["ladder-input-min-" + index][0].focus();
        // }, 100);
      }
    },
    del(item) {
      console.log(item, "item");
      if (item.id) {
        item.avaliable = 0;
      } else {
        const deItemIndex = this.value.findIndex((v) => v === item);
        this.value.splice(deItemIndex, 1);
      }
      // const deepCopyVaule = JSON.parse(JSON.stringify(this.avaliableList));
      this.$nextTick(() => {
        // this.$emit("input", this.avaliableList);
        this.checkMinMaxChange();
      });

      // }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_responsive.scss";
.min-input {
  .el-input__inner {
    text-align: center;
  }
}
.number-input {
  width: 100%;
  @include mobile {
    margin-bottom: 8px;
  }
}
.from-to {
  text-align: center;
}
.el-button.ladder-submit {
  display: block;
  margin-left: 0;
  margin-top: 10px;
}
</style>