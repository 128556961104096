<template>
    <el-input-number
      :precision="2"
      :step="0.01"
      :min="0.01"
      :placeholder="$t(placeholder)"
      :value="value / 100"
      @change="change"
      autocomplete="autocomplete"
      :size="config.size"
      :controls-position="config.controlsPosition"
    ></el-input-number>
  </template>
  
  <script>
  export default {
    props: {
      placeholder: {
        type: String,
      },
      value: {
        type: Number,
      },
      config: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    methods: {
      change(e) {
        console.log("000000000:", e);
        const outputValue = parseFloat((e * 100).toFixed(0));
        this.$emit("input", outputValue);
        this.$store.commit("setChannelPrice", outputValue);
      },
    },
  };
  </script>
  
  <style>
  </style>