<template>
  <el-select
    :value="value"
    :placeholder="$t('common.selectPlaceholder')"
    autocomplete="autocomplete"
    filterable
    clearable
    @change="handleChange"
  >
    <el-option
      v-for="item in list"
      :key="item.label + item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>
<script>
import { getExchateRateListCom } from "@/utils/api";

export default {
  props: {
    value: {
      default: "",
    },
  },

  data() {
    return {
      list: [],
    };
  },

  created() {
    this.getExchaterateList();
  },

  methods: {
    getExchaterateList() {
      getExchateRateListCom().then((res) => {
        console.log("getExchateRateListCom-res:", res);
        this.list = res.data.rows.map((v) => {
          return {
            label: v.currencyFrom,
            value: v.currencyFrom,
          };
        });
      });
    },

    handleChange(e) {
      console.log("handleChange:", e);
      this.$emit("input", e);
    },
  },
};
</script>