
<template>
  <div class="brand-select">
    <el-row :gutter="20" type="flex">
      <el-col :md="12">
        <el-select
          :value="value"
          :placeholder="$t('common.selectPlaceholder')"
          autocomplete="autocomplete"
          filterable
          clearable
          @change="handleChange"
          :popper-append-to-body="true"
          popper-class="brand-select"
        >
          <template v-if="value" v-slot:prefix>
            <img class="brand-select-img" :src="srcObject" />
          </template>
          <el-option
            ref="list"
            v-for="(item, index) in list"
            :key="`brand_${index}`"
            :label="item.label"
            :value="item.value"
          >
            <img
              v-if="item.label"
              :src="item.img + '?x-oss-process=style/w64'"
              alt=""
              class="brand-select-img"
            />
            <span>{{ item.label }}</span>
          </el-option>
        </el-select>
      </el-col>

      <el-col :md="4">
        <el-button
          type="text"
          icon="el-icon-plus"
          :loading="loading"
          class="brand-tips"
          @click="add"
          >{{ $t("product.creatbrand") }}</el-button
        >
      </el-col>

      <el-col :md="4">
        <el-button
          type="text"
          icon="el-icon-refresh-left"
          :loading="loading"
          @click="refresh"
          >{{ $t("common.VersionUpdateRefreshNow") }}</el-button
        >
      </el-col>
    </el-row>

    <el-drawer
      :title="`${$t('common.add')} ${$t('common.brand')}`"
      :visible="editing"
      size="500px"
      close-on-press-escape
      @close="close"
    >
      <sui-form
        ref="form"
        :list="formList"
        :defaultdata="formData"
        @submit="submit"
      />
    </el-drawer>
  </div>
</template>
<script>
// 懒加载
const SuiForm = () => import("@/components/s-ui/form");
import { AddBrand, GetBrandList } from "@/utils/api";
const defaultFormData = {
  brandName: "",
  img: "",
  id: "",
};

export default {
  props: {
    value: {
      default: "",
    },
  },
  components: { SuiForm },
  computed: {
    //计算属性 下拉框的img
    srcObject: function () {
      // 先找到这个brand的id
      const selectBrand = this.list.find((v) => v.value === this.value);
      console.log("selectBrand", selectBrand);

      // 如果有brand的id并且有brand的img
      if (selectBrand && selectBrand.img) {
        // return此img
        return selectBrand.img + "?x-oss-process=style/w64";
      } else {
        // 否则return ""
        return "";
      }
    },
  },
  data() {
    return {
      loading: false,
      editing: false,
      list: [],
      // form表单的内容
      formList: [
        {
          type: "input",
          name: "brandName",
          label: "brandList.brandName",
          rules: [
            {
              required: true,
              message: "brandList.brandReq",
              trigger: "blur",
            },
          ],
        },
        {
          type: "uploadimage",
          mode: "material",
          name: "img",
          label: "brandList.img",
        },
      ],
      formData: {},
    };
  },
  created() {
    this.getList();
  },

  methods: {
    // 刷新
    refresh() {
      this.getList();
      this.loading = true;
      this.$message({
        type: "success",
        duration: 5000,
        message: this.$t("brandList.refresh"),
      });
      if (this.list.length) {
        this.loading = false;
      }
    },
    // 关闭侧边栏
    close() {
      this.editing = false;
      this.formData = {};
      this.$refs["form"].resetFields();
    },
    // 侧边栏提交
    submit(data) {
      console.log("submit:", data);
      // 添加品牌的接口
      AddBrand(data)
        .then((res) => {
          console.log("AddBanner-res:", res);
          // 添加品牌成功显示message
          this.$message({
            type: "success",
            message: this.$t("common.createSuccess"),
          });
          // 侧边栏关闭
          this.editing = false;
          // 获取品牌的列表
          this.getList();
          // console.log(res);
          // 使用下拉框的change事件得到input框中的id
          this.handleChange(res.data.id);
        })
        .catch((err) => {
          console.log("AddBanner-err:", err);
        });
    },
    // 新增品牌
    add() {
      this.editing = true;
      const formData = {
        ...defaultFormData,
      };
      this.formData = formData;
      this.$nextTick(() => {
        this.formData = formData;
      });
    },
    // 获取品牌
    getList() {
      GetBrandList().then((res) => {
        this.list = res.data.rows.map((v) => {
          return {
            label: v.brandName,
            img: v.img,
            value: v.id,
          };
        });
        console.log("getList", this.list);
      });
    },
    // 下拉选择器的change事件
    handleChange(e) {
      this.$emit("input", e);
    },
  },
};
</script>



<style lang="scss">
.brand-select {
  .brand-select-img {
    width: 34px;
    height: 34px;
    object-fit: contain;
    background-image: url("http://oss.sokogate.com/static/photoshop_background.png");
    margin-right: 8px;
  }
  .el-select-dropdown__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0;

    .flag {
      margin-right: 8px;
    }
  }

  .el-input--prefix {
    .el-input__inner {
      padding-left: 55px;
    }
    .el-input__prefix {
      margin-left: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
  .brand-tips {
    color: red;
    cursor: pointer;
  }
}
</style>