<template>
  <div class="tag-list">
    <template v-for="(item, index) in value">
      <el-tag
        :key="`taglist_${index}`"
        :disable-transitions="false"
        v-if="!!item.avaliable"
      >
        {{ item.specName }}
        <el-popconfirm
          :title="$t('common.confirmDelete')"
          @confirm="del(index)"
        >
          <i slot="reference" class="el-icon-delete close-hover" />
        </el-popconfirm>
      </el-tag>
    </template>

    <el-input
      class="input-new-tag"
      v-if="inputVisible"
      v-model.trim="inputValue"
      v-loading="inputLoading"
      maxlength="128"
      ref="saveTagInput"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    >
    </el-input>

    <el-button
      class="button-new-tag"
      v-else
      icon="el-icon-plus"
      type="primary"
      plain
      @click="showInput"
    ></el-button>

    <el-collapse v-if="avaliableCount > 0" v-model="unavailable" accordion>
      <el-collapse-item name="unavailable">
        <template slot="title">
          <el-button type="text">
            {{ $t("product.showDeletedItems") }}
            ({{ avaliableCount }})
          </el-button>
        </template>
        <template v-for="(item, index) in value">
          <el-tag
            :key="`taglist_${index}`"
            :disable-transitions="false"
            type="info"
            v-if="!item.avaliable"
          >
            {{ item.specName }}
            <i
              class="sokogate icon-yihuifu-xiugai close-hover"
              @click="restore(index)"
            />
          </el-tag>
        </template>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>


<script>
export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      inputLoading: false,
      inputVisible: false,
      inputValue: "",
      unavailable: "",
    };
  },
  computed: {
    avaliableCount: function () {
      return this.value.filter((v) => !v.avaliable).length;
    },
  },
  methods: {
    del(index) {
      this.value[index].avaliable = 0;
    },
    restore(index) {
      this.value[index].avaliable = 1;
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      if (this.inputValue.length > 0) {
        //  判断输入字符串不为空
        const sameSpecNameItem = this.value.find(
          (v) => v.specName === this.inputValue
        );
        console.log("handleInputConfirm-sameSpecNameItem:", sameSpecNameItem);
        if (sameSpecNameItem) {
          //  判断输入的字符串已存在列表
          this.$message({
            type: "warning",
            message: this.$t("product.warningmsg"),
          });

          if (!sameSpecNameItem.avaliable) {
            sameSpecNameItem.avaliable = 1;
          }
        } else {
          this.$emit("input", [
            ...this.value,
            { specName: this.inputValue, avaliable: 1 },
          ]);
        }
        this.closeInput();
      } else {
        this.closeInput();
      }
    },

    closeInput() {
      this.inputVisible = false;
      this.inputValue = "";
    },
  },
};
</script>


<style lang="scss">
.tag-list {
  .el-tag {
    margin-right: 10px;
  }
  .button-new-tag {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    white-space: pre;
    width: 90px;
    vertical-align: bottom;
  }
  .close-hover {
    border-radius: 50%;
    padding: 5px;
    &:hover {
      color: #fff;
      background-color: #409eff;
    }
  }
}
</style>