<template>
  <div>
    <tips
      :text="$t('common.tips') + '：' + $t('productManagement.parameter')"
    />
    <el-row
      v-for="(item, index) in value"
      :key="`dic-input-${index}`"
      style="margin-bottom: 10px"
      type="flex"
      :gutter="1"
    >
      <el-col :span="20" :md="6">
        <el-input
          v-model.trim="item.key"
          class="input-with-prepend"
          @blur="keyBlur"
          :ref="'dic-input-key-' + index"
          :placeholder="$t('productManagement.attributeColorplaceholder')"
        >
          <div slot="prepend">
            <i class="el-icon-key"></i>
          </div>
        </el-input>
      </el-col>
      <el-col :span="20" :md="6">
        <el-input
          v-model.trim="item.value"
          class="input-with-prepend"
          :placeholder="$t('productManagement.attributeColorRemark')"
        >
          <div slot="prepend">
            <i class="el-icon-paperclip"></i>
          </div>
        </el-input>
      </el-col>
      <el-col :span="4" :md="2">
        <el-popconfirm
          v-if="item.key.length > 0"
          :title="$t('common.confirmDelete')"
          @confirm="del(index)"
        >
          <el-button
            slot="reference"
            icon="el-icon-delete"
            type="danger"
            plain
          ></el-button>
        </el-popconfirm>
      </el-col>
    </el-row>
    <el-button
      v-if="!hasEmptyKey"
      icon="el-icon-plus"
      type="primary"
      plain
      @click="add"
    ></el-button>
  </div>
</template>

<script>
import Tips from "@/components/s-ui/form/Tips";
export default {
  components: { Tips },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      newDic: {
        key: "",
        value: "",
      },
    };
  },
  computed: {
    hasEmptyKey: function () {
      return this.value.some((v) => !v.key.length);
    },
  },
  methods: {
    keyBlur() {
      this.findAndDelEmptyDic();
    },
    findAndDelEmptyDic() {
      if (this.hasEmptyKey) {
        const emptyKeyDic = this.value.find((v) => !v.key.length);
        const emptyKeyDicIndex = this.value.indexOf(emptyKeyDic);
        this.value.splice(emptyKeyDicIndex, 1);
      }
    },
    add() {
      if (this.hasEmptyKey) {
        this.$message({
          type: "success",
          message: "有修改未保存",
        });
      } else {
        this.$emit("input", [
          ...this.value,
          JSON.parse(JSON.stringify(this.newDic)),
        ]);
        setTimeout(() => {
          const index = this.value.length - 1;
          this.$refs["dic-input-key-" + index][0].focus();
        }, 100);
      }
    },
    del(index) {
      this.value.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.input-with-prepend {
  padding-right: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
}
</style>