<template>
  <div class="code-box">
    <el-input
      :value="value"
      @input="handleChange"
      :placeholder="$t('login.verificationCode')"
      class="send_code"
      :autocomplete="autocomplete"
    >
    </el-input>
    
    <el-button
      v-if="sendCodeButtonStatus"
      :loading="loading"
      class="send_code_btn"
      @click.stop="sendCode"
      >{{ sendCodeText }}</el-button
    >
    <el-button v-else class="send_code_btn"
      >{{ count }}{{ sendCodeResultText }}</el-button
    >
  </div>
</template>


<script>
import { AddVerifyCode } from "@/utils/api";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default() {
        return {};
      },
    },
    autocomplete: {
        type: String,
        default: '',
    }
  },
  data() {
    return {
      timer: null,
      count: 60,
      loading: false,
      sendCodeButtonStatus: true,
      sendCodeText: this.$t("login.sendCodeText"),
      sendCodeResultText: this.$t("login.sendCodeResultText"),
    };
  },
  methods: {
    handleChange(e) {
      console.log("handleChange", e);
      this.$emit("input", e);
    },
    initTime() {
      this.sendCodeButtonStatus = false;
      this.timer = setInterval(() => {
        if (this.count > 0) {
          this.count--;
          this.sendCodeButtonStatus = false;
        } else {
          this.sendCodeButtonStatus = true;
          this.count = 60;
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },
    sendCode() {
      this.loading = true;
      const say = {
        codeType: 2,
        userType: 3,
        cond: this.formData.email,
      };
      AddVerifyCode(say)
        .then((res) => {
          this.loading = false;
          this.initTime();
          console.log("AddVerifyCode-res", res);
          this.$message({
            showClose: false,
            message: this.$t("login.sendCodeTips"),
            type: "success",
          });
        })
        .catch((err) => {
          console.log("AddVerifyCode-err", err);
          if (err.errcode === 2102) {
            this.loading = false;
            this.$message({
              showClose: false,
              message: err.errmsg,
              type: "warning",
            });
          } else if (err.errcode === 1902) {
            this.loading = false;
            this.$message({
              showClose: false,
              message: err.errmsg,
              type: "warning",
            });
          } else {
            this.loading = false;
            this.$message({
              showClose: false,
              message: err.errmsg,
              type: "warning",
            });
          }
        });
    },
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="scss" scoped>
.code-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .send_code_btn {
    background: #ff0a0a;
    color: #fff;
    width: 180px !important;
    margin-top: 0px !important;
    margin-left: 5px !important;
    border: none;
    border-radius: 10px !important;
  }
}
</style>