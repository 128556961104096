<template>
  <p v-if="showTips">
    <el-tag closable type="info" @close="closeTips" class="sui-tips">
      <i class="el-icon-s-opportunity flash" />
      {{ text }}
    </el-tag>
  </p>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
  data() {
    return {
      showTips: true,
    };
  },
  methods: {
    closeTips() {
      this.showTips = false;
    },
  },
};
</script>

<style lang="scss">
.sui-tips.el-tag.el-tag--info {
  height: auto;
  white-space: normal;
  line-height: 18px;
  padding: 8px 10px;
  margin: 6px 0;
}

.flash {
  animation: lightning 3s infinite;
}
@keyframes lightning {
  0% {
    color: gray;
  }

  50% {
    color: black;
  }

  100% {
    color: gray;
  }
}
</style>