<template>
  <el-input-number
    :precision="4"
    :step="0.0001"
    :min="0.0"
    :placeholder="$t(placeholder)"
    :value="value / 10000"
    @change="change"
    autocomplete="autocomplete"
    :size="config.size"
    :controls-position="config.controlsPosition"
  ></el-input-number>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
    },
    value: {
      type: Number,
    },
    config: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    change(e) {
      //   console.log("change", e);
      const outputValue = parseFloat((e * 10000).toFixed(4));
      console.log("outputValue", outputValue);
      this.$emit("input", outputValue);
    },
  },
};
</script>

<style>
</style>