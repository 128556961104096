<template>
  <div class="volume-input-number">
    <el-row :gutter="10">
      <el-col :md="4" :xs="8">
        <el-input-number
          v-model="volumeL"
          :precision="precision"
          :min="min"
          :step="min"
          controls-position="right"
          :placeholder="$t('productManagement.l')"
        ></el-input-number>
      </el-col>
      <el-col :md="1" :xs="0" class="volume-times">
        <i class="el-icon-close" />
      </el-col>
      <el-col :md="4" :xs="8">
        <el-input-number
          v-model="volumeW"
          :precision="precision"
          :min="min"
          :step="min"
          controls-position="right"
          :placeholder="$t('productManagement.w')"
        ></el-input-number>
      </el-col>
      <el-col :md="1" :xs="0" class="volume-times">
        <i class="el-icon-close" />
      </el-col>
      <el-col :md="4" :xs="8">
        <el-input-number
          v-model="volumeH"
          :precision="precision"
          :min="min"
          :step="min"
          controls-position="right"
          :placeholder="$t('productManagement.h')"
        ></el-input-number>
      </el-col>
      <el-col :md="1" :xs="24" class="volume-times">
        <i class="el-icon-minus" />
      </el-col>
      <el-col :md="4" :xs="15">
        <el-select
          v-model="unitTimes"
          :placeholder="$t('resident.pleaseSelect')"
          @change="changeUnit"
        >
          <div slot="prefix" class="volume-unit">
            {{ $t("product.unit") }}
          </div>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="$t(item.label)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :md="5" :xs="8" v-if="capacitySum"
        >{{ $t("product.Total Capacity") }}: {{ capacitySum }} CBM</el-col
      >
    </el-row>
  </div>
</template>

<script>
export default {
  name: "VolumeInputNumber",
  props: {
    // value默认的参数是"0.1*0.1*0.1"
    value: {
      type: String,
      default: "0.1*0.1*0.1",
    },
  },
  data() {
    return {
      // 默认是cm
      unitTimes: 0.01,
      options: [
        // 毫米
        {
          value: 0.001,
          label: "product.units.mm",
        },
        // 厘米
        {
          value: 0.01,
          label: "product.units.cm",
        },
        // 米
        {
          value: 1,
          label: "product.units.m",
        },
      ],
    };
  },
  computed: {
    capacitySum() {
      return this.$utils.keepTwoDecimal(
        this.$utils.recoverCmbNum(this.value, this.incMinimun)
      );
    },
    incMinimun() {
      return this.$store.state.incMinimun;
    },
    volumeArray: function () {
      // console.log("this.value:", this.value);
      // 先把value使用split切割*成三份
      const vol = this.value.split("*");
      // console.log("volumeArray:", vol);
      // 如果vol的长度为3
      if (vol.length === 3) {
        // 遍历vol
        return vol.map((v) => {
          // 转成数字进行转换并保留三位小数
          const result = Number((v / this.unitTimes).toFixed(3));
          // console.log("vol.map:", v, this.unitTimes, result);
          // 如果结果大于最小的值就得到结果不然就是最小值
          return result > this.min ? result : this.min;
        });
      } else {
        // 否则返回[0,0,0]
        return [0, 0, 0];
      }
    },
    // 长
    volumeL: {
      get() {
        return this.volumeArray[0];
      },
      set(newVal) {
        this.valueOutPut(0, newVal);
      },
    },
    // 宽
    volumeW: {
      get() {
        return this.volumeArray[1];
      },
      set(newVal) {
        this.valueOutPut(1, newVal);
      },
    },
    // 高
    volumeH: {
      get() {
        return this.volumeArray[2];
      },
      set(newVal) {
        this.valueOutPut(2, newVal);
      },
    },
    // 最小值
    min: function () {
      switch (this.unitTimes) {
        // m每次增加0.001 cm每次增加0.1 mm每次增加1
        case 1:
          return 0.001;
        case 0.01:
          return 0.1;
        default:
          return 1;
      }
    },
    // 保留的小数位
    precision: function () {
      switch (this.unitTimes) {
        // m保留三位小数位 cm保留以为小数位 mm不保留小数位
        case 1:
          return 3;
        case 0.01:
          return 1;
        default:
          return 0;
      }
    },
  },
  methods: {
    // 体积的set
    valueOutPut(index, value) {
      // 深拷贝体积的长宽高
      const outPutArray = JSON.parse(JSON.stringify(this.volumeArray));
      // value赋值给体积的长宽高
      outPutArray[index] = value;
      // 遍历体积的长宽高并进行转换保留三位小数
      const outPutNumberArray = outPutArray.map((v) =>
        (v * this.unitTimes).toFixed(3)
      );
      // console.log("valueOutPut.outPutNumberArray:", outPutNumberArray);
      // 再把体积的长宽高通过*分割转换为字符串
      this.$emit("input", outPutNumberArray.join("*"));
    },
    // 下拉框的chage事件
    changeUnit(e) {
      console.log("changeUnit-e:", e);
    },
  },
};
</script>

<style lang="scss">
.volume-input-number {
  .el-input-number {
    width: 100%;
  }
  .volume {
    &-times {
      text-align: center;
    }
    &-unit {
      margin-right: 10px;
    }
  }
  .el-input--prefix {
    .el-input__inner {
      padding-left: 50px;
    }
  }
}
</style>