<template>
  <div class="tag-list">
    <el-tag
      :key="`taglist_${index}`"
      v-for="(item, index) in value"
      :disable-transitions="false"
    >
      {{ item }}
      <el-popconfirm
        :title="$t('common.confirmDelete')"
        @confirm="handleDelete(index)"
      >
        <i slot="reference" class="el-icon-close close-hover" />
      </el-popconfirm>
    </el-tag>

    <el-input
      class="input-new-tag"
      v-if="inputVisible"
      v-model.trim="inputValue"
      maxlength="12"
      ref="saveTagInput"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    >
    </el-input>
    <el-button
      class="button-new-tag"
      v-else
      icon="el-icon-plus"
      type="primary"
      plain
      @click="showInput"
    ></el-button>
  </div>
</template>


<script>
export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      inputVisible: false,
      inputValue: "",
    };
  },
  methods: {
    handleDelete(tag) {
      this.value.splice(this.value.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      if (this.inputValue.length > 0) {
        //  判断输入字符串不为空
        if (this.value.indexOf(this.inputValue) > -1) {
          //  判断输入的字符串已存在列表
          this.$message({
            type: "warning",
            message: this.$t("product.warningmsg"),
          });
        } else {
          this.$emit("input", [...this.value, this.inputValue]);
        }
        this.closeInput();
      } else {
        this.closeInput();
      }
    },

    closeInput() {
      this.inputVisible = false;
      this.inputValue = "";
    },
  },
};
</script>


<style lang="scss">
.tag-list {
  .el-tag {
    margin-right: 10px;
  }
  .button-new-tag {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    white-space: pre;
    width: 90px;
    vertical-align: bottom;
  }
  .close-hover {
    &:hover {
      color: #fff;
      background-color: #409eff;
    }
  }
}
</style>