<template>
  <div class="upload-image-list">
    <template v-if="item.mediaType === 'video'">
      <el-dropdown
        class="upload-image-list-item"
        v-for="(videoId, videoIndex) in value"
        :key="`upload-video-${videoIndex}`"
        placement="right"
        @command="tapUploadVideoDelete(videoIndex)"
      >
        <div class="el-image">
          <video-box :id="videoId" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-delete">
            {{ $t("common.delete") }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <template v-else-if="value">
      <el-dropdown
        class="upload-image-list-item"
        v-for="(img, imgIndex) in item.max ? value : [value]"
        :key="`upload-image-${imgIndex}`"
        placement="right"
        @command="tapUploadDelete(item.name, item, imgIndex)"
      >
        <el-image
          :src="`${img}?x-oss-process=style/w256`"
          :preview-src-list="item.max ? value : [value]"
          class="imgbg"
          fit="contain"
        ></el-image>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-delete">
            {{ $t("common.delete") }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <template v-if="(item.max && value && item.max > value.length) || !!!value">
      <material-selector
        v-if="item.mode === 'material'"
        :count="item.max"
        :type="item.mediaType || 'image'"
        @receiver="onUploadSuccess($event, item)"
      >
        <template v-slot:custom-open-button>
          <div class="el-upload el-upload--picture-card">
            <i class="el-icon-plus"></i>
            <i class="el-icon-picture-outline"></i>
          </div>
        </template>
      </material-selector>
      <div v-else style="width: 360px; height: 180px">
        <upload-image-notoken @onsuccess="onUploadSuccess($event, item)" />
      </div>
    </template>
  </div>
</template>

<script>
import MaterialSelector from "@/components/MaterialSelector";
import UploadImageNotoken from "@/components/UploadImageNotoken";
import VideoBox from "@/components/VideoBox.vue";

export default {
  props: {
    value: {
      type: [Array, String],
    },
    item: {
      type: Object,
      default() {
        return {
          mode: "",
          mediaType: "image",
        };
      },
    },
  },
  components: {
    MaterialSelector,
    UploadImageNotoken,
    VideoBox,
  },
  methods: {
    onUploadSuccess(value, item) {
      console.log("onUploadSuccess:", item, value);
      if (item.mediaType === "video") {
        this.$emit("input", [value.id]);
      } else if (item.max) {
        if (Array.isArray(value)) {
          const newValue = [
            ...(this.value || []),
            ...value.map((v) => v.filename),
          ];
          console.log("newValue:", newValue);
          this.$emit("input", newValue);
        } else {
          this.$emit("input", value.filename);
        }
      } else {
        this.$emit("input", value.filename);
      }
    },

    tapUploadDelete(name, item, index) {
      if (item.max) {
        const newValue = this.value;
        newValue.splice(index, 1);
        console.log("newValue:", newValue);
        this.$emit("input", newValue);
      } else {
        this.$emit("input", "");
      }
    },

    tapUploadVideoDelete(index) {
      const newValue = this.value;
      newValue.splice(index, 1);
      console.log("newValue:", newValue);
      this.$emit("input", newValue);
    },
  },
};
</script>

<style lang="scss">
.upload-image-list {
  display: flex;
  flex-wrap: wrap;

  &-item {
    margin: 0 10px 10px 0;
  }

  .el-image {
    width: 180px;
    height: 180px;
  }

  .el-upload--picture-card {
    height: 180px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>