<template>
  <div>
    <el-skeleton v-if="loading" :rows="5" :loading="loading" animated />
    <template v-else>
      <el-cascader-panel
        v-loading="loading"
        :value="value"
        :options="tree"
        :props="{ expandTrigger: 'hover', checkStrictly: true }"
        @change="handleChange"
        clearable
        class="product-edit-category-cascader"
      >
        <template slot-scope="{ node, data }">
          <span>{{ $t(`category.${data.label}`) }}</span>
          <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
        </template>
      </el-cascader-panel>
      <el-tree
        ref="categoryTree"
        :data="tree"
        node-key="value"
        :default-expanded-keys="value"
        :props="defaultProps"
        :highlight-current="true"
        accordion
        @current-change="handleCurrentChange"
        class="product-edit-category-tree"
      >
        <template slot-scope="{ node, data }">
          <span :class="{ ckecked: treeNodeChecked(data) }">
            <i v-if="treeNodeChecked(data)" class="el-icon-circle-check" />
            {{ $t(`category.${node.label}`) }}
          </span>
        </template>
      </el-tree>
    </template>
  </div>
</template>
<script>
import { GetMyCategoryList } from "@/utils/api";

export default {
  props: {
    value: {
      default: [],
    },
  },
  data() {
    return {
      loading: true,
      tree: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  created() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      GetMyCategoryList({})
        .then((res) => {
          console.log("getCategory-res:", res);
          const tree = this.formatCategory(res.data.rows);
          // console.log("tree:", tree);
          this.tree = tree;
          this.loading = false;
        })
        .catch((error) => {
          console.log("getCategory-error:", error);
        });
    },
    formatCategory(list) {
      return list.map((v) => {
        const options = v.children.length
          ? { children: this.formatCategory(v.children) }
          : {};
        return {
          value: v.id,
          label: v.categoryName,
          ...options,
        };
      });
    },
    handleChange(e) {
      // console.log("handleChange-e:", e);
      this.$emit("input", e);
      // console.log('key:', e[e.length - 1]);
      this.$refs.categoryTree.setCurrentKey(e[e.length - 1]);
    },
    handleCurrentChange(data) {
      // console.log("handleCurrentChange-data:", data, data.value);
      const node = this.$refs.categoryTree.getNode(data.value);
      // console.log("handleCurrentChange-node:", this.findNodeParent(node));
      this.$emit("input", this.findNodeParent(node));
    },
    findNodeParent(node) {
      if (node.parent) {
        const parent = this.findNodeParent(node.parent);
        return [...parent, node.data.value];
      } else {
        return [];
      }
    },
    treeNodeChecked(data) {
      return this.value.indexOf(data.value) > -1;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_responsive.scss";
.title {
  padding: 10px;
}
.product-edit-category-cascader {
  width: 100%;
  @include mobile {
    display: none;
  }
}
.product-edit-category-tree {
  border: 1px solid #e4e7ed;
  display: none;
  @include mobile {
    display: inherit;
  }
  .ckecked {
    color: #409eff;
  }
}
</style>